import React from 'react';
import { bool, func } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  ListItem,
  Drawer,
  Grid,
  List,
  Link,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import colors from '../../constants/colors';

const SideNav = (props) => {
  const { isOpen, onClose, onSignOut } = props;
  const classes = useStyles();

  const sideMenuItems = [
    { name: 'Plants', link: '/plants' },
    { name: 'Gardens', link: '/gardens' },
    { name: 'Users', link: '/users' },
    { name: 'Admins', link: '/admins' },
  ]

  return (
    <Drawer open={isOpen} onClose={onClose} classes={{ paper: classes.drawer }}>
      <Grid container wrap='nowrap' alignItems='center' className={classes.drawerHead}>
        <Grid item xs={3}>
          <IconButton color='inherit' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        {/* <Grid item xs={6} container justify='center'>
          <Link href={'/'} color="inherit">
            <img src="/images/logo.svg" alt="GrowApp" />
          </Link>
        </Grid> */}
      </Grid>
      <Grid
        className={classes.drawerBody}
        justify='space-between'
        direction='column'
        wrap='nowrap'
        container
      >
        <Grid item component='nav'>
          <List>
            {sideMenuItems.map(
              (m) =>
                m &&
                (m.component ? (
                  m.component
                ) : (
                    <ListItem key={m.name} button className={classes.listItem} component='li'>
                      <Link href={m.link} naked>
                        {m.name}
                      </Link>
                    </ListItem>
                  ))
            )}
              <ListItem button className={classes.listItem} component='li' onClick={onSignOut}>
                Sign out
              </ListItem>
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );
};

SideNav.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onSignOut: func.isRequired,
};

export default SideNav;

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: colors.green,
    color: colors.white,
    maxWidth: '70%',
    width: '70%',
  },
  drawerHead: {
    padding: theme.spacing(2.5, 1.5),
    height: 100,
  },
  drawerBody: {
    height: 'calc(100vh - 100px)',
  },
  drawerFooter: {
    padding: theme.spacing(2.5),
  },
  listItem: {
    padding: theme.spacing(2.5),
    fontSize: 24,
    fontWeight: 'bold',
    display: 'block',
    '& a': {
      textDecoration: 'none',
      color: colors.white,
    },
  },
  listSubItem: {
    fontSize: 20,
    '&:hover': {
      textDecoration: 'none',
      color: colors.white,
    },
  },
}));
