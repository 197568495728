import React, { Component, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Cookies from "js-cookie";
import { withStyles } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import authService from "../services/auth";
import authActions from "../redux/actions/auth";
import { bindActionCreators } from "../redux/utils";
import setAxiosHeaders from "../utilities/setHeaders";
import "react-toastify/dist/ReactToastify.min.css";

import Navigation from "../components/Header/Navbar";
import ErrorDialog from "../components/ErrorDialog";
import Loader from "../components/Loader";
import PublicRoutes from "../routes/PublicRoutes";
import PrivateRoutes from "../routes/PrivateRoutes";

const styles = (theme) => ({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(8),
  },
});

class App extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    const {
      props: { setUser },
    } = this;

    setAxiosHeaders();

    if (!Cookies.get("token")) {
      return this.setState({ isLoading: false });
    } else
      authService
        .getCurrentUser()
        .then((user) => {
          setUser(user);
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 401) {
            Cookies.remove("token");
            // history.push("/sign-in");
          }
          this.setState({ isLoading: false });
        });
  }

  render() {
    const {
      state: { isLoading },
    } = this;

    const authenticated = !!(this.props.user && this.props.user.id);

    return isLoading ? (
      <Loader />
    ) : (
      <>
        <Suspense fallback={<Loader />}>
          <Router>
            {authenticated ? (
              <>
                <Navigation />
                <PrivateRoutes />
              </>
            ) : (
              <PublicRoutes />
            )}

            <ToastContainer position="top-right" autoClose={3000} />
          </Router>
        </Suspense>
        <ErrorDialog />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    setUser: authActions.setUser,
  });

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(App);
