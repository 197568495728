import React from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { BASE_URL } from "./constants/api";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./pages/App";
import axios from "axios";

import colors from "./constants/colors";
import "./index.css";

axios.defaults.baseURL = `${BASE_URL}/`;

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Roboto",
    h1: {
      fontWeight: "bold",
      fontSize: 24,
      "@media (min-width: 600px)": {
        fontSize: 34,
      },
      "@media (min-width: 1280px)": {
        fontSize: 44,
      },
    },
    h2: {
      fontWeight: "bold",
      fontSize: 18,
      "@media (min-width: 600px)": {
        fontSize: 24,
      },
      "@media (min-width: 1280px)": {
        fontSize: 34,
      },
    },
    h3: {
      fontWeight: "bold",
      fontSize: 16,
      "@media (min-width: 600px)": {
        fontSize: 18,
      },
      "@media (min-width: 1280px)": {
        fontSize: 24,
      },
    },
    h4: {
      fontWeight: "bold",
      fontSize: 14,
      "@media (min-width: 600px)": {
        fontSize: 16,
      },
      "@media (min-width: 1280px)": {
        fontSize: 18,
      },
    },
    h5: {
      fontWeight: "bold",
      fontSize: 12,
      "@media (min-width: 600px)": {
        fontSize: 14,
      },
      "@media (min-width: 1280px)": {
        fontSize: 16,
      },
    },
    subtitle1: {
      fontSize: 16,
      "@media (min-width: 1280px)": {
        fontSize: 18,
      },
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: "400",
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
  },
  props: {
    MuiTextField: {
      fullWidth: true,
      variant: "outlined",
      InputLabelProps: {
        shrink: true,
      },
      InputProps: {
        notched: false,
      },
      SelectProps: {
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        },
      },
    },
    MuiButton: {
      variant: "contained",
    },
    MuiCard: {
      elevation: 0,
    },
  },
  overrides: {
    MuiChip: {
      root: {
        borderRadius: 4,
        "&.MuiChip-colorPrimary": {
          color: colors.white,
          background: colors.chipGreen,
        },
        "&.MuiChip-colorSecondary": {
          color: colors.white,
          backgroundColor: colors.mediumGrey,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        fontSize: 14,
        fontWeight: "bold",
        color: colors.black,
        "&.MuiInputLabel-shrink": {
          transform: "translate(0, 0) scale(1)",
          marginTop: -10,
        },
      },
    },
    MuiTableRow: {
      root: {},
    },
    MuiInputBase: {
      multiline: {
        padding: "none",
      },
    },
    MuiButton: {
      root: {
        textDecoration: "none",
        textTransform: "none",
        padding: "6px 24px",
        fontWeight: 400,
        borderRadius: 4,
        minWidth: 114,
        fontSize: 16,
      },
      containedPrimary: {
        backgroundColor: colors.contrastYellow,
        color: colors.black,
        boxShadow: "none",
        "&$disabled": {
          backgroundColor: colors.disabledYellow,
          color: colors.mediumGrey,
        },
        "&:hover": {
          backgroundColor: colors.lightYellow,
          boxShadow: "none",
        },
      },
      containedSecondary: {
        backgroundColor: colors.pink,
        color: colors.white,
        boxShadow: "none",
        "&$disabled": {
          backgroundColor: colors.grey,
          color: colors.white,
        },
        "&:hover": {
          backgroundColor: colors.darkestPink,
          boxShadow: "none",
        },
      },
      outlinedPrimary: {},
      outlinedSecondary: {},
    },
    MuiCard: {
      root: {
        border: `4px solid ${colors.lightBlue1}`,
        borderRadius: 0,
      },
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: 16,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        marginBottom: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.black,
        "&:hover": {
          borderColor: colors.darkGrey,
        },
        "label + &": {
          marginTop: 10,
          color: colors.black,
        },
      },
      input: {
        padding: 15,
      },
      notchedOutline: {
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.black,
      },
    },
    MuiSelect: {
      icon: {
        color: colors.actionOnLight,
      },
    },
  },
  palette: {
    primary: { main: colors.black },
    secondary: { main: colors.lightYellow },
    background: {
      primary: {
        lightest: "#FAFAFA",
        light: "#eeeeee",
        main: "#9e9e9e",
        dark: "#212121",
      },
      secondary: {
        lightest: "#E0F7FA",
        light: "#4DD0E1",
        main: "#00BCD4",
        dark: "#006064",
      },
    },
    tonalOffset: 0.2,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
