import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "./reducers";

import plantsSaga from "./sagas/plants.saga";
import phasesSaga from "./sagas/phases.saga";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(plantsSaga);
sagaMiddleware.run(phasesSaga);

export default store;
