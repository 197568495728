import { setError } from "../actions/errors";
// import { navigate } from "../../constants/functions";
import { call, takeLatest, put } from "redux-saga/effects";
import {
  setPlants,
  setPlant,
  deletePlant,
  updatePlants,
  selectPlant,
} from "../actions/plants.action";
import {
  PLANTS_REQUEST,
  SHOW_PLANT_REQUEST,
  DELETE_PLANT_REQUEST,
  EDIT_PLANT_REQUEST,
} from "../actions/plants.action";
import plantsService from "../../services/plants";
import notifications from "../../utilities/notifications";

export function* requestData(action) {
  try {
    const data = yield call(plantsService.getAllPlants, action.filters);
    yield put(setPlants(data));
  } catch (e) {
    notifications.error(e.response.data.err.message);
  }
}

export function* requestPlant(action) {
  try {
    const data = yield call(plantsService.getPlantById, action.id);
    yield put(setPlant(data));
  } catch (e) {
    notifications.error(e.response.data.err.message);
  }
}

export function* requestDeletePlant(action) {
  try {
    const data = yield call(plantsService.remove, action.id);
    yield data.status === 204 && put(deletePlant(action.id));
  } catch (e) {
    notifications.error(e.response.data.err.message);
    setError(e.response.data);
  }
}

export function* requestUpdatePlant(action) {
  try {
    const res = yield call(plantsService.updatePlant, action.data, action.id);

    yield put(updatePlants(res.data.plant));
    yield put(selectPlant({ id: res.data.plant.id }));
  } catch (e) {
    setError(e.message);
    notifications.error(e.response.data.err.message);
  }
}

export default function* rootSaga() {
  yield takeLatest(PLANTS_REQUEST, requestData);
  yield takeLatest(SHOW_PLANT_REQUEST, requestPlant);
  yield takeLatest(DELETE_PLANT_REQUEST, requestDeletePlant);
  yield takeLatest(EDIT_PLANT_REQUEST, requestUpdatePlant);
}
