import React from "react";
import { ReactComponent as LogOut } from "./LogOut.svg";
import SvgIcon from "@material-ui/core/SvgIcon";

const LogOutIcon = () => (
  <SvgIcon>
    <LogOut />
  </SvgIcon>
);

export default LogOutIcon;