import { combineReducers } from 'redux';
import auth from './auth';
import errors from './errors'
import plants from './plants.reducer'

export default combineReducers({
  auth,
  errors,
  plants,
});
