export default {
  green: "#63A484",
  lightGreen: "#EEF3F2",
  pastelGreen: "#E3FCF1",

  chipGreen: "#5BD2A0",
  mediumGreen: "#47A582",
  lightMediumGreen: "#7FB99C",

  darkGreen: "#329C7F",
  lightDarkGreen: "#3FA98C",
  successGreen: "#EDFCF0",

  lightYellow: "#FFF1AC",
  disabledYellow: "#FFF9DE",
  contrastYellow: "#FFEB8A",

  white: "#fff",
  grey: "#F7F7F7",
  black: "#2F3733",

  mediumGrey: "#C2C2C2",
  darkGrey: "#A7A7A7",
  lightGrey: "#F6F6F6",
  placeholderGrey: "#6F6F6F",

  pink: "#EB6868",
  lightPink: "#FFBFBF",
  darkestPink: "#e05c5c",
};