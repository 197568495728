export const PLANTS_REQUEST = "PLANTS/PLANTS_REQUEST";
export const getPlantsRequest = (filters) => ({ type: PLANTS_REQUEST, filters });

export const SET_PLANTS = "PLANTS/SET_PLANTS";
export const setPlants = (data) => ({ type: SET_PLANTS, data });

export const CREATE_NEW_PLANT_REQUEST = "PLANTS/CREATE_NEW_PLANT_REQUEST";
export const createNewPlantRequest = (body) => ({ type: CREATE_NEW_PLANT_REQUEST, body });

export const ADD_NEW_PLANT = "PLANTS/ADD_NEW_PLANT";
export const addNewPlant = (item) => ({ type: ADD_NEW_PLANT, item });

export const EDIT_PLANT_REQUEST = "PLANTS/EDIT_PLANT_REQUEST";
export const editPlantAction = (data, id) => ({ type: EDIT_PLANT_REQUEST, data, id, });

export const UPDATE_PLANT = "PLANTS/UPDATE_PLANT";
export const updatePlants = (item) => ({ type: UPDATE_PLANT, item });

export const DELETE_PLANT_REQUEST = "PLANTS/DELETE_ITEM_REQUEST";
export const deletePlantRequest = (id) => ({ type: DELETE_PLANT_REQUEST, id });

export const DELETE_PLANT = "PLANTS/DELETE_PLANT";
export const deletePlant = (id) => ({ type: DELETE_PLANT, id });

export const SHOW_PLANT_REQUEST = "PLANTS/SHOW_PLANT_REQUEST";
export const showPlantAction = (id) => ({ type: SHOW_PLANT_REQUEST, id });

export const SET_PLANT = "PLANTS/SET_PLANT";
export const setPlant = (item) => ({ type: SET_PLANT, item });

export const CLEAR_STATE = "PLANTS/CLEAR_STATE";
export const clearPlantsState = () => ({ type: CLEAR_STATE });

export const SELECT_PLANTS = "PLANTS/SELECT_PLANTS";
export const selectPlant = item => ({ type: SELECT_PLANTS, item })

export const SET_FILTERS = "PLANTS/SET_FILTERS";
export const setFilters = filters => ({ type: SET_FILTERS, filters })

export const SET_SELECTED = "PLANTS/SET_SELECTED";
export const setSelected = selected => ({ type: SET_SELECTED, selected})
