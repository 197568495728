import {
  SET_PLANT,
  SET_PLANTS,
  CLEAR_STATE,
  UPDATE_PLANT,
  DELETE_PLANT,
  SELECT_PLANTS,
  SET_SELECTED,
  SET_FILTERS,
} from "../actions/plants.action";

const initialState = {
  countOutOfSeason: 0,
  countPublished: 0,
  countInSeason: 0,
  countDraft: 0,
  countAll: 0,

  plants: [],
  plant: {},

  selected: [],
  filters: {
    page: 1,
    limit: 30,
    filter: "all",
    q: "",
  },
  count: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PLANTS:
      let countState = 0;
      for (const [key, value] of Object.entries(action.data)) {
        if (
          key.toLowerCase().indexOf(state.filters.filter.toLowerCase()) > -1
        ) {
          countState = value;
        }
      }

      const {
        countOutOfSeason,
        countPublished,
        countInSeason,
        countDraft,
        countAll,
        plants,
      } = action.data;

      return {
        ...state,
        countOutOfSeason,
        countPublished,
        countInSeason,
        countDraft,
        countAll,
        plants,
        count: countState,
      };

    case UPDATE_PLANT:
      const editPlantsList = state.plants.map((item) =>
        item.id === action.item.id ? action.item : item
      );
      return {
        ...state,
        plants: editPlantsList,
        plant: action.item,
      };

    case DELETE_PLANT:
      const deleteData = state.plants.filter((item) => item.id !== action.id);
      return {
        ...state,
        plants: deleteData,
      };

    case SET_PLANT:
      return {
        ...state,
        plant: action.item,
      };

    case SELECT_PLANTS:
      const selectedIndex = state.selected.findIndex(
        (selectedItem) => selectedItem.id === action.item.id
      );
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(state.selected, action.item);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state.selected.slice(1));
      } else if (selectedIndex === state.selected.length - 1) {
        newSelected = newSelected.concat(state.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state.selected.slice(0, selectedIndex),
          state.selected.slice(selectedIndex + 1)
        );
      }
      return {
        ...state,
        selected: newSelected,
      };

    case SET_SELECTED:
      return {
        ...state,
        selected: action.selected,
      };

    case SET_FILTERS:
      let count = 0;
      for (const [key, value] of Object.entries(state)) {
        if (
          key.toLowerCase().indexOf(state.filters.filter.toLowerCase()) > -1
        ) {
          count = value;
        }
      }
      return {
        ...state,
        filters: action.filters,
        count,
      };

    case CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
