import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import { useDispatch, useSelector } from 'react-redux';
import errorsActions from '../redux/actions/errors'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ErrorDialog() {
  const dispatch = useDispatch()
  const errorMessage = useSelector(state => state.errors.errorMessage)
  const handleClose = () => {
    dispatch(errorsActions.setError(null))
  };

  return (
    <div>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        TransitionComponent={Transition}
        open={Boolean(errorMessage)}
        onClose={handleClose}
        keepMounted
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}