import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

const SignIn = lazy(() => import("../pages/SignIn"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));

const PublicRoutes = () => {
  return (
    <Switch>
      <Route exact path="/sign-in" component={SignIn} />
      <Route exact path="/password-forgot/:token" component={ResetPassword} />

      <Route component={() => <Redirect to="/sign-in" />} />
    </Switch>
  );
};

export default PublicRoutes;
