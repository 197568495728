import { call, takeLatest } from "redux-saga/effects";
import { getAllPlants } from "../../services/plants";

export function* requestData(action) {
  try {
    const data = yield call(getAllPlants, action.sort);
    console.log(data);
    // yield put(setUserSpots(data.rows));
  } catch (e) {
    console.error(e);
  }
}

export default function* rootSaga() {
  yield takeLatest("USER_SPOTS_REQUEST", requestData);
}
