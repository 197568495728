import axios from "axios";
import { stringifyQuery } from "../constants/functions";

const getPlantById = (id) => axios.get(`/plants/${id}`);

const getAllPlants = (params) =>
  axios.get(stringifyQuery("/plants", params)).then((res) => res.data);

const addNewPlant = (body) =>
  axios.post("/plants", body).then((res) => res.data);

const updatePlant = (body, id) =>
  axios.put(`/plants/${id}`, body).then((res) => res);

const remove = (id) => axios.delete(`/plants/${id}`).then((res) => res);

export { getPlantById, getAllPlants, remove, addNewPlant, updatePlant };
export default { getPlantById, getAllPlants, remove, addNewPlant, updatePlant };
