
const stringifyQuery = (api, queryConfig) => {
  if (!queryConfig) {
    queryConfig = api;
    api = "";
  }

  return Object.keys(queryConfig).reduce(
    (url, key) =>
    !queryConfig[key] ? url : `${url}&${key}=${queryConfig[key]}`,
    `${api}?`
    );
  };
  
  function humanizeCamelCase(camelCaseString) {
    return camelCaseString
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
}
  
  function navigate(history, path, state = null, scrollToTop = true) {
  history.push(path, state);
  scrollToTop && window.scrollTo(0, 0);
}

export { stringifyQuery, humanizeCamelCase, navigate };
export default { stringifyQuery, humanizeCamelCase, navigate };
