import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Cookie from "js-cookie";
import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import colors from "../../constants/colors";
import MenuIcon from "@material-ui/icons/Menu";
import SideNav from "./Sidebar";
import { navigate } from "../../constants/functions";
import LogOutIcon from "../../assets/icons/LogOut";

export default function Navigation(props) {
  const history = useHistory();
  const classes = useStyles();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(window.location.pathname);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleSignOut = async () => {
    Cookie.remove("token");
    delete axios.defaults.headers.common.Authorization;
    window.location.reload();
    history.push("/sign-in");
  };

  const menuItems = [
    { name: "Plants", link: "/plants" },
    { name: "Gardens", link: "/gardens" },
    { name: "Users", link: "/users" },
    { name: "Admins", link: "/admins" },
    { name: "Guides", link: "/guides" },
    { name: "Oslo Area", link: "/oslo" },
    { name: "Ask The Expert", link: "/ask/expert" },
  ];

  useEffect(() => {
    menuItems.map(
      (item) =>
        window.location.pathname.includes(item.link) && setActiveItem(item.link)
    );
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <SideNav
        onSignOut={handleSignOut}
        onClose={toggleDrawer}
        isOpen={isDrawerOpen}
      />
      <Grid
        className={classes.root}
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
        container
      >
        <Grid item className={classes.menuButton}>
          <IconButton color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item container></Grid>
        <Grid item className={classes.menu}>
          <Grid
            container
            justify="center"
            alignItems="center"
            component="nav"
            style={{ width: "100%", flexWrap: "nowrap" }}
          >
            {menuItems.map(
              (m) =>
                m && (
                  <Grid
                    className={`${classes.bold} ${classes.menuItem} ${
                      m.link === activeItem && classes.activeFont
                    }`}
                    onClick={() => {
                      navigate(history, m.link);
                      setActiveItem(m.link);
                    }}
                    key={m.name}
                    item
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        style={{
                          paddingTop: 20,
                          paddingLeft: 10,
                          paddingRight: 10,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {m.name}
                      </Typography>
                    </Grid>
                    {m.link === activeItem && (
                      <div className={classes.activeItem}></div>
                    )}
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
        <Grid item container justify="flex-end">
          <Grid item>
            <IconButton onClick={() => handleSignOut()}>
              <LogOutIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  menuButton: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menuItem: {
    height: 60,
    minWidth: 60,
    display: "flex",
    marginRight: 20,
    cursor: "pointer",
    color: colors.darkGrey,
    flexDirection: "column",
  },
  activeItem: {
    borderTopRightRadius: 2,
    borderTopLeftRadius: 2,
    background: "#5BD2A0",
    marginTop: "auto",
    width: "100%",
    height: 3,
  },
  activeFont: {
    color: colors.black,
    fontWeight: 500,
  },
  menu: {
    display: "none",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  bold: {
    fontSize: 16,
    "&:hover": {
      textDecoration: "none",
      boxShadow: "inset 0 -2px 0 white, inset 0 -1px 0 white",
    },
    "&.active": {
      boxShadow: "inset 0 -2px 0 white, inset 0 -1px 0 white",
    },
  },
  root: {
    boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.08)",
    padding: theme.spacing(0, 1.5),
    height: 60,
    zIndex: 2,
  },
  logo: {
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
}));
