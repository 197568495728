import axios from 'axios';

import Cookies from 'js-cookie';

import authActions from '../redux/actions/auth';

const getCurrentUser = () => {
  const token = Cookies.get('token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  return axios
    .get('/admins/me')
    .then(res => res.data);
};

const signIn = body =>
  axios
    .post('/admins/login', body)
    .then(res => {
      setToken(res.data.token);
      return res;
    });

const signOut = () => dispatch => {
  Cookies.remove('token');
  delete axios.defaults.headers.common.Authorization;
  dispatch(authActions.setUser(null));
};

const setToken = token => {
  Cookies.set('token', token);
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const sendResetToken = body => axios
    .post("/password/resets", body)
    .then((res) => res.data)

const setNewPassword = (token, password) => axios.put("/password/resets/" + token, { password }).then(res => res.data)


export {
  signIn,
  signOut,
  sendResetToken,
  getCurrentUser,
  setNewPassword,
};

export default {
  signIn,
  signOut,
  sendResetToken,
  getCurrentUser,
  setNewPassword,
};

