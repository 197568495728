const SET_USER = 'AUTH/SET_USER';
const RESET = 'AUTH/RESET';
const LOGIN_REQIEST = "AUTH/LOGIN_REQUEST";

export {
  SET_USER,
  RESET,
  LOGIN_REQIEST
};

export default {
  SET_USER,
  RESET,
  LOGIN_REQIEST
};
