import axios from "axios";
import Cookies from "js-cookie";

const setAxiosHeaders = () => {
  const token = Cookies.get("token");

  if (token) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export default setAxiosHeaders;
