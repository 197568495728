import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

const CreatePlant = lazy(() => import("../pages/Plants/CreatePlant"));
const PlantInfo = lazy(() => import("../pages/Plants/components/PlantInfo"));
const Gardens = lazy(() => import("../pages/Gardens"));
const Plants = lazy(() => import("../pages/Plants"));
const Users = lazy(() => import("../pages/Users"));
const UserInfo = lazy(() => import("../pages/UserInfo"));
const GardenInfo = lazy(() => import("../pages/GardenInfo"));
const Admins = lazy(() => import("../pages/Admins"));
const AdminInfo = lazy(() => import("../pages/AdminInfo"));
const Guides = lazy(() => import("../pages/Guides"));
const GuideInfo = lazy(() => import("../pages/GuideInfo"));
const OsloArea = lazy(() => import("../pages/OsloArea"));
const PostDetails = lazy(() => import("../pages/PostDetails"));
const AskTheExpert = lazy(() => import("../pages/AskTheExpert"));

const PrivateRoutes = () => {
  return (
    <Switch>
      <Route exact path="/plants/add" component={CreatePlant} />
      <Route exact path="/plants/:id" component={PlantInfo} />
      <Route exact path="/edit/:id" component={PlantInfo} />
      <Route exact path="/gardens" component={Gardens} />
      <Route exact path="/plants" component={Plants} />
      <Route exact path="/users" component={Users} />
      <Route exact path="/users/:id" component={UserInfo} />
      <Route exact path="/gardens/:id" component={GardenInfo} />
      <Route exact path="/admins" component={Admins} />
      <Route exact path="/admins/show/:id" component={AdminInfo} />
      <Route exact path="/admins/create" component={AdminInfo} />
      <Route exact path="/guides" component={Guides} />
      <Route exact path="/guides/show/:id" component={GuideInfo} />
      <Route exact path="/guides/create" component={GuideInfo} />
      <Route exact path="/oslo" component={OsloArea} />
      <Route exact path="/oslo/:id" component={PostDetails} />
      <Route exact path="/ask/expert" component={AskTheExpert} />
      <Route exact path="/ask/expert/:id" component={PostDetails} />

      <Route component={() => <Redirect to="/plants" />} />
    </Switch>
  );
};

export default PrivateRoutes;
